main {
  display: block;
  background: $white;
  margin: 0;
  padding: $gutter / 2 0;

  min-height: 50vh;

  @include media(tablet) {
    padding: 15px 0;
    min-height: 74vh;
  }
}

.breadcrumbs {
  display: none;
  color: $black;

  @include media(tablet) {
    display: block;
    margin: 0;
    margin-top: 0;
    margin-bottom: -$gutter * 0.67;
    font-size: 14px;

    .breadcrumbs-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-link {
      text-decoration: none;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-list-item {
      display: inline-block;
      position: relative;
      margin-left: $gutter / 3;
      padding-left: $gutter / 4;
      margin-bottom: $gutter / 6;

      &:before {
        content: "/";
        color: $black;
        display: block;
        position: absolute;
        top: 3px;
        left: -4px;
        margin: auto 0;
      }

      &:first-child {
        margin-left: 0;
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }
}
.modal{
  display: block;
  height: 100vh;
  background: rgba(0,0,0, 0.7);
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  overflow: hidden;
}
.modal-dialog{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border: 4px solid #000;
  min-width: 400px;
  button{
    margin-left: 0px;
  }
  .heading-large{
    margin-top: 0;
  }
  p {
    font-size: 19px;
  }
}
.sign-up-page{
  .error-summary{
      margin-top: 0;
  }
}
#id-section-help-initialPassword {
  width: 50%;
}
#content-activity-popup{
  .form-group{
    .form-control-1-16{
      label{
        font-size: 19px;
    }
    }
    .form-control-1-10{
      label{
        font-size: 19px;
      }
    }
    .form-label-b19{
      font-size: 24px;
      font-family: 'helvetica_bold';
    }
  }
  #id-endDate{
    .form-group{
      margin-bottom: 5px;
    }
  }
}
#generate_cv {
  .inlineRadioButtons {
    display: inherit;
  }
}
#myCvUpload {
  .inlineRadioButtons {
    display: block;
    margin-bottom: 30px;
  }
}

#planPathwayDescription{
  .selectOptions{
    button.column-fifth{
      height: 5em!important;
    }
  }
} 
/*for track based on age*/
.track-young{
  display: none;
}
.showTrack{
  display: none;
}
.track-adult{
  display: inline-block;
}
.lowerAge {
  .track-young{
    display: inline-block;
  }
  .track-adult{
    display: none!important;
  }
  .goals-alert-container{
    background: none!important;
    border: 12px solid #F7E113!important;
    border-radius: 12px;
    overflow: auto;
    margin-bottom: 40px!important;
    .showTrack{
      display: block!important;
      text-align: center;
      padding-top: 50px;
    }
    .width20{
      width: 20%;
      padding-left: 3%;
      padding-right: 3%;
      float: left;
      img{
        width: 60%;
      }
    }
    .goals-container{
      float: left;
      width: 48%;
      .goals-alert-heading{
        text-align: center;
        font-size: 36px;
        margin-top: 20px;
      }
    }
  }
  #tabWrapper.tabWrapper { 
    ul {
      height: auto!important;
      border-color: transparent!important;
      text-align: center;
      border-bottom: none!important;
      li{
        float: none!important;
        background-color: #fff!important;
        height: auto!important;
        padding: 14px 19px 8px 19px!important;
        a{
          text-decoration: none!important;
          img{
            width: 60px;
            display: block!important;
            text-align: center;
            margin: auto;
            margin-bottom: 8px;
          }
        }
      }
      li.active{
        background-color: #F3F2F1!important;
        border-color: transparent!important;
        border-bottom: 4px solid #3770B3!important;
        border-radius: 12px 12px 0 0;
        margin-top: 0!important;
        padding: 14px 19px 8px 19px!important;
      }
    }
  }
  .plan-new-wrapper{
    background-color: #85D5D9;
    border-color: transparent!important;
    border-radius: 12px;
    margin-top: -6px;
    .gantt-container{
      max-width: 917px!important;
    }
    .upper-container{
      .months-container{
        .previous-month{
          border-left-color: transparent!important;
        }
        //color: #fff!important;
      }
    }
    .pathway-container{
      .pathway-timeline-education-training-and-employment{
        background: rgba(244, 119, 56, 1);
      }
      .pathway-timeline-finance{
        background: rgba(40, 161, 151, 1);
      }
      .pathway-timeline-health-and-wellbeing{
        background: rgba(86, 148, 202, 1);
      }
      .pathway-timeline-housing{
        background: rgba(243, 153, 190, 1);
      }
      .pathway-timeline-drugs-and-alcohol{
        background: rgba(133, 153, 75, 1);
      }
      .pathway-timeline-family{
        background: rgba(111, 114, 175, 1);
      }
      .pathway-timeline-behaviour-and-attitudes{
        background: rgba(145, 43, 136, 1);
      }
      .timeline-decorator-container{
        background: rgba(255, 255, 255, 0.6);
      }
      .pathway-label-container{
        display: block!important;
        position: relative;
        border-radius: 10px 0 0 10px;
        .centre_aligned{
          width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        img{
          display: block;
          margin: auto;
          margin-bottom: 3px;
        }
      }
      .pathway-timeline{
        width: calc(100% - 134px)!important;
      }
      .triangle-right {
        width: 0;
        height: auto;
        border-top: 43px solid transparent;
        border-left: 50px solid #555;
        border-bottom: 43px solid transparent;
        position: relative;
      }
    }
    // .entry-label-content{
    //   color: #fff!important;
    // }
    .key-information-panel{
      border: 4px solid #FFDD00;
      border-radius: 8px;
      position: relative;
      padding: 20px 10px!important;
      margin-bottom: 80px!important;
      .quarter-section{
        position: absolute;
        width: 100%!important;
        left: 0;
        text-align: center;
        bottom: -65px;
        img{
          margin: auto;
        }
      }
    }
  }
  .entry-panel{
    border-radius: 15px;
    padding: 30px 40px 30px!important;
    position: relative;
  }
  app-entry-comment{
    .entry-panel{
      background: #AF4AF7!important;
      color: #fff!important;
      padding: 25px 40px 25px!important;
      .triangle-left {
        width: 0;
        height: auto;
        border-top: 20px solid transparent;
        border-right: 30px solid #AF4AF7;
        border-bottom: 20px solid transparent;
        position: absolute;
        left: -25px;
        bottom: 25px;
      }
    }
  }
  app-entry-appointment, app-entry-system, app-entry-intervention, app-entry-induction{
    .entry-panel{
      background: #ffffff!important;
    }
  }
  app-entry-intervention{
    .customPad{
      padding: 30px 40px 80px!important;
    }
  }
  .f-structure-entry-container{
    .f-line{
      background-color: transparent!important;
    }
    .f-line-container{
      margin-right: 0!important;
      .f-line-icon-container{
        background-color: #EC2589!important;
        border:transparent!important;
        border-radius: 10px!important;
        width: 65px!important;
        height: 57px!important;
        margin-top: 10px;
        z-index: 9;
      }
    }
    .entry-panel-container{
      margin-top: -20px;
      margin-left: -25px;
      .entry-title{
        background: #EC2589;
        color: #fff;
        display: inline-block;
        padding: 14px 15px 7px 15px;
        margin-left: -21px;
        border-radius: 10px;
        margin-bottom: 5px;
      }
    }
    .today-line-panel-container{
      .today-line-divider{
       background-color: #EC2589!important;
     }
   }
  }
  .left-container{
    position: relative!important;
    .image-div{
        position: absolute;
        right: 0;
        top: 18px;
    }
  }
  .right-container{
    position: absolute;
    left: 0;
    bottom: -67px;
    width: 100%!important;
    text-align: center;
    display: block!important;
    span{
      margin: auto;
      vertical-align: top;
    }
    .track-ml20{
      margin-left: 20px;
    }
    .status-spacer{
      background-color: transparent;
      border: none;
    }
  }
  .image-system-div{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-33%, -50%);
  } 
  .track-tab-container{
    .entry-pathway-label{
      border-radius: 10px 0 0 10px;
      height: 30px;
    }
    .small-triangle-right{
      width: 0;
      height: auto;
      border-top: 19px solid transparent;
      border-left: 30px solid #555;
      border-bottom: 19px solid transparent;
    }
  }
  .icon_align{
    margin-right: 7px;
    margin-left: 7px;
  }
  .load-more-container{
    padding-top: 60px!important;
    a{
      margin-left: 100px!important;
    }
  }
}
@media screen and (max-width: 480px){
  .lowerAge {
    .goals-alert-container {
      .width20{
        display: none!important;
      }
      .goals-container{
        width: 100%!important;
      }
    }
    #tabWrapper.tabWrapper{
      ul {
        overflow: hidden!important;
        li {
          width: calc(100% - 30px)!important;
          a{
            img{
              display: inline-block!important;
              width: 26px!important;
            }
          }
        }
      }
    }
    .plan-new-wrapper{
      margin-top: 10px!important;
      .pathway-container{
        .pathway-label-container{
          width: 26px!important;
        }
        .pathway-timeline{
          width: calc(100% - 32px) !important;
        }
        .triangle-right{
          display: none!important;
        }
      }
    } 
    .image-div, .image-system-div{
      display: none!important;
    }
    .f-structure-entry-container{
      .entry-panel-container{
        margin-left: 0!important;
      }
    }
    .entry-pathway-label{
      height: 35px!important;
      p{
        font-size: 12px!important;
      }
    }
  }
}